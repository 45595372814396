import { initializeApp } from "firebase/app"
import "firebase/compat/auth"
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
    setPersistence,
    browserSessionPersistence
} from "firebase/auth"
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc
} from "firebase/firestore"
import dayjs from "dayjs"

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
setPersistence(auth, browserSessionPersistence)
    .then(() => {
        return null
    })
    .catch((error) => {
        console.log("auth_error", error.code, error.message)
    })

const db = getFirestore(app)
const googleProvider = new GoogleAuthProvider()
const signInWithGoogle = async () => {
    try {
        const res = await signInWithPopup(auth, googleProvider)
        const user = res.user
        const q = query(collection(db, "users"), where("uid", "==", user.uid))
        const docs = await getDocs(q)
        if (docs.docs.length === 0) {
            await addDoc(collection(db, "users"), {
                uid: user.uid,
                name: user.displayName,
                authProvider: "google",
                email: user.email,
                subscribed: true,
                timestamp: dayjs().format("DD/MMMM/YYYY HH:mm:ss")
            })
        } else {
            await addDoc(collection(db, "logs"), {
                uid: user.uid,
                name: user.displayName,
                authProvider: "google",
                email: user.email,
                timestamp: dayjs().format("DD/MMMM/YYYY HH:mm:ss")
            })
        }
    } catch (err) {
        console.error(err)
        alert("Kindly login using your gmail account")
    }
}

const logInWithEmailAndPassword = async (email, password) => {
    try {
        const res = await signInWithEmailAndPassword(auth, email, password)
        const user = res.user

        await addDoc(collection(db, "logs"), {
            uid: user.uid,
            name: user.displayName,
            authProvider: "google",
            email: user.email,
            timestamp: dayjs().format("DD/MMMM/YYYY HH:mm:ss")
        })
    } catch (err) {
        console.error(err.code)
        alert("Kindly enter correct Email and Password")
    }
}

const registerWithEmailAndPassword = async (name, email, password) => {
    // const history = useHistory();

    try {
        const res = await createUserWithEmailAndPassword(auth, email, password)
        const user = res.user
        let completedFlag = await addDoc(collection(db, "users"), {
            authProvider: "local",
            email: email,
            name: name,
            subscribed: true,
            uid: user.uid,
            timestamp: dayjs().format("DD/MMMM/YYYY HH:mm:ss")
        }).then((rese) => {
            return true
        })

        return completedFlag
    } catch (err) {
        console.error(err)
        alert("Kindly enter suitable Email and Password")
        return false
    }
}

const sendPasswordReset = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email)
        alert("Password reset link sent!")
    } catch (err) {
        console.error(err)
        // alert(err.message);
        alert("Kindly enter correct E-mail")
    }
}

const logout = (history) => signOut(auth)

const storeShareLogs = async (source) => {
    let user = auth.currentUser
    console.log(user, Object.keys(user))

    await addDoc(collection(db, "share-logs"), {
        email: user.email,
        name: user.displayName,
        source: source,
        timestamp: dayjs().format("DD/MMMM/YYYY HH:mm:ss")
    }).then((rese) => {
        return true
    })
}

const getCurrentUser = async () => {
    let user = auth.currentUser
    return user
}

export {
    auth,
    db,
    signInWithGoogle,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    logout,
    storeShareLogs,
    getCurrentUser
}
