import { useEffect, useMemo, useState } from "react"
import wordCloud from "highcharts/modules/wordcloud.js"
import Highcharts from "highcharts/highstock"
import HighchartsReact from "highcharts-react-official"
import {
    Typography,
    Button,
    ButtonGroup,
    FormControl,
    Card,
    CardHeader,
    CardContent,
    IconButton
} from "@mui/material"
import fullScreen from "highcharts/modules/full-screen"
import api from "./api_ui"
import Moment from "moment"
import FullscreenOutlinedIcon from "@mui/icons-material/FullscreenOutlined"
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import Select from "react-select"
import { getGraphData } from '../utils/functions'

import "highcharts/css/annotations/popup.css"

wordCloud(Highcharts)
require("highcharts/modules/exporting")(Highcharts)
fullScreen(Highcharts)

const wcdata = [
    //   {
    //   name: 'Lorem',
    //   weight: 3
    // }, {
    //   name: 'Ipsum',
    //   weight: 2
    // }, {
    //   name: 'Dolor',
    //   weight: 1
    // }
]
const Sources = [
    { value: "All", label: "All" },
    { value: "Stocktwits", label: "Social Media" },
    { value: "News", label: "News" }
    // { value: "berry", label: "Berry" },
]

const ticker_options = ["META", "AAPL", "AMZN", "NFLX", "GOOGL"].map(
    (ticker) => {
        return { value: ticker, label: ticker }
    }
)

const seriesNames = ["Company", "Keyword", "Author", "Person"]
const wordcloudLabels = {
    Company: "Company",
    Keyword: "Keyword",
    Author: "Author",
    Person: "Mentioned Person"
}

const colorDict = {
    Concept: "#264653",
    Company: "#2A9D8F",
    Keyword: "#8E5572",
    Author: "#F4A261",
    Person: "#B7B5E4"
}
const filterWCData = (stdata, newsdata, key) => {
    const fields = ["Author", "Company", "Person", "Keyword"]
    let arr3 = []
    let stDates = {
        one_day: 0,
        one_week: 1,
        two_week: 2,
        one_month: 3,
        two_month: 4
    }
    let newsDates = {
        one_day: 6,
        one_week: 7,
        two_week: 8,
        one_month: 9,
        two_month: 10
    }
    for (const field_name of fields) {
        arr3[field_name] = [
            ...stdata[stDates[key]][field_name],
            ...newsdata[newsDates[key]][field_name]
        ]
    }
    return arr3
}

const filterSourceWCData = (data, key, source = null) => {
    const fields = ["Author", "Company", "Person", "Keyword"]
    let arr3 = []
    let stDates = {
        one_day: 0,
        one_week: 1,
        two_week: 2,
        one_month: 3,
        two_month: 4
    }
    let newsDates = {
        one_day: 6,
        one_week: 7,
        two_week: 8,
        one_month: 9,
        two_month: 10
    }
    for (const field_name of fields) {
        if (source === "Stocktwits") {
            arr3[field_name] = [...data[stDates[key]][field_name]]
        } else if (source === "News") {
            arr3[field_name] = [...data[newsDates[key]][field_name]]
        }
    }
    return arr3
}

const Wordcloud = (props) => {
    const [ticker, setTicker] = useState("AMZN")
    // const [ticker_options, setTickerOptions] = useState([]);
    const [selectedDate, setSelectedDate] = useState(1)
    const [displayDates, setDisplayDates] = useState("")
    const [WCViews, setWCViews] = useState("one_day")
    const [datesList, setDatesList] = useState([]) //["2022-06-05", "2022-06-05", "2022-05-29", "2022-05-22", "2022-05-06", "2022-04-06", "2022-03-07", "2022-06-05"]
    const [wordcloudSeries, setWordcloudSeries] = useState(wcdata)
    // const wordcloudSeriesRef = useRef(wcdata)
    const [wcSource, setSource] = useState("All")
    const [isLoading, setIsLoading] = useState(false)

    const screen = useFullScreenHandle()
    const customStyles = useMemo(
        () => ({
            option: (provided, state) => ({
                ...provided,
                fontFamily: "Poppins",
                fontSize: 18
                // color:"#7E7E7E"
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                fontFamily: "Poppins",
                fontSize: 16,
                backgroundColor: "inherit",
                color: "#7E7E7E"
            }),
            container: (provided, state) => ({
                ...provided,
                fontFamily: "Poppins",
                backgroundColor: "inherit",
                fontSize: 16,
                color: "#7E7E7E"
            }),
            control: (base) => ({
                ...base,
                border: 0,
                borderRadius: 0,
                backgroundColor: "inherit",
                borderBottom: "solid 1px black",
                // This line disable the blue border
                boxShadow: "none",
                color: "#7E7E7E"
            }),
            indicatorSeparator: (provided, state) => ({
                ...provided,
                color: "red",
                backgroundColor: "inherit",
                color: "#7E7E7E"
            }),
            indicatorsContainer: (provided, state) => ({
                ...provided,
                padding: 1,
                backgroundColor: "inherit",
                color: "#7E7E7E"
            })
        }),

        []
    )

    const setViews = (date) => {
        let datesKVPair = {
            one_day: datesList[0],
            one_week: datesList[2],
            two_week: datesList[3],
            one_month: datesList[4],
            two_month: datesList[5]
        }
        // let datesKVPairChange = {"one_day":datesList[7],"one_week":datesList[2],"two_week":datesList[3],"one_month":datesList[4],"two_month":datesList[5]}
        setWCViews((prevState) => {
            return date
        })
        setDisplayDates((prevState) => {
            return (
                Moment(datesKVPair[date]).format("DD/MMM/YYYY") +
                " > " +
                Moment(datesList[1]).format("DD/MMM/YYYY")
            )
        })
    }

    const setData = async () => {
        if (!isLoading) {
            setIsLoading(true)
            if (wcSource === "All") {
                await getGraphData('worldcloud').then((worldcloudItems) => {
                    const data = worldcloudItems[ticker]

                    let mergedData = filterWCData(
                        data.data,
                        data.data,
                        WCViews
                    )
                    try {
                        if (datesList.length === 0) {
                            setDatesList(() => {
                                return data.dates
                            })
                        }
                    } catch (r) {
                        if (datesList.length === 0) {
                            setDatesList(() => {
                                return data.dates
                            })
                        }
                    }
                    // mergedData = mergedData
                    let tempData = mergedData["Keyword"].filter(
                        (row) => row.name !== "Chars"
                    )
                    mergedData["Keyword"] = tempData
                    let finalSeries = []
                    for (let key of seriesNames) {
                        let counts = mergedData[key].reduce(
                            (prev, curr) => {
                                let count = prev.get(curr.name) || 0
                                prev.set(
                                    curr.name,
                                    parseInt(curr.weight) + count,
                                    curr.color
                                )
                                return prev
                            },
                            new Map()
                        )

                        // then, map your counts object back to an array
                        let reducedObjArr = [...counts].map(
                            ([name, weight, color]) => {
                                return { name, weight, color }
                            }
                        )
                        finalSeries.push({
                            // data: mergedData[key] .filter(i => i.name && i.weight).filter((v,i,a)=>a.findIndex(v2=>['name'].every(k=>v2[k].toLowerCase() ===v[k].toLowerCase()))===i),
                            data: reducedObjArr,
                            name: wordcloudLabels[key],
                            type: "wordcloud",
                            showInLegend: true,
                            color: colorDict[key],
                            // data: wordcloudSeries.filter(i => i.name && i.weight),
                            maxFontSize: 24,
                            spiral: "rectangular",
                            minFontSize: 8,
                            xAxis: seriesNames.indexOf(key),
                            // name:"Occurences",
                            colorByPoint: false,
                            shadow: true,
                            style: {
                                // symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                width: 20,
                                height: 20
                            }
                        })
                    }
                    setWordcloudSeries(() => {
                        return finalSeries
                    })
                })
            } else if (wcSource === "Stocktwits") {
                await getGraphData('worldcloud').then((worldcloudItems) => {
                    const data = worldcloudItems[ticker]

                    let mergedData = filterSourceWCData(
                        data.data,
                        WCViews,
                        wcSource
                    )
                    try {
                        if (datesList.length === 0) {
                            setDatesList(() => {
                                return data.dates
                            })
                        }
                    } catch (r) {
                        if (datesList.length === 0) {
                            setDatesList(() => {
                                return data.dates
                            })
                        }
                    }
                    // mergedData = mergedData
                    let finalSeries = []
                    for (let key of seriesNames) {
                        finalSeries.push({
                            data: mergedData[key].filter(
                                (i) => i.name && i.weight
                            ),
                            name: wordcloudLabels[key],
                            type: "wordcloud",
                            showInLegend: true,
                            // data: wordcloudSeries.filter(i => i.name && i.weight),
                            maxFontSize: 18,
                            spiral: "rectangular",
                            minFontSize: 8,
                            xAxis: seriesNames.indexOf(key),
                            // name:"Occurences",
                            shadow: true,
                            style: {
                                // symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                width: 16,
                                height: 16
                            }
                        })
                    }
                    setWordcloudSeries(() => {
                        return finalSeries
                    })
                })
            } else if (wcSource === "News") {
                await getGraphData('worldcloud').then((worldcloudItems) => {
                    const data = worldcloudItems[ticker]

                    let mergedData = filterSourceWCData(
                        data.data,
                        WCViews,
                        wcSource
                    )
                    try {
                        if (datesList.length === 0) {
                            setDatesList(() => {
                                return data.dates
                            })
                        }
                    } catch (r) {
                        if (datesList.length === 0) {
                            setDatesList(() => {
                                return data.dates
                            })
                        }
                    }
                    let tempData = mergedData["Keyword"].filter(
                        (row) => row.name !== "Chars"
                    )
                    mergedData["Keyword"] = tempData
                    // console.log(mergedData)
                    // mergedData = mergedData
                    let finalSeries = []
                    for (let key of seriesNames) {
                        finalSeries.push({
                            data: mergedData[key].filter(
                                (i) => i.name && i.weight
                            ),
                            name: wordcloudLabels[key],
                            type: "wordcloud",
                            showInLegend: true,
                            // data: wordcloudSeries.filter(i => i.name && i.weight),
                            maxFontSize: 18,
                            spiral: "rectangular",
                            minFontSize: 8,
                            xAxis: seriesNames.indexOf(key),
                            // name:"Occurences",
                            shadow: true,
                            style: {
                                // symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                width: 16,
                                height: 16
                            }
                        })
                    }
                    setWordcloudSeries(() => {
                        return finalSeries
                    })
                 })
            }
            setIsLoading(false)
        }
    }

    const SetDataNew = async () => {
        let mergedData = null
        // let seriesNames = null
        if (wcSource === "All") {
            console.log("IN ALL")
            if (ticker === "FB/META") {
                setTicker(() => {
                    return "FB"
                })
            }
            await getGraphData('worldcloud').then((worldcloudItems) => {
                const data = worldcloudItems[ticker]
                
                mergedData = filterWCData(data.data, data.data, WCViews)

                try {
                    if (datesList.length === 0) {
                        setDatesList(() => {
                            return data.dates
                        })
                    }
                } catch (r) {
                    if (datesList.length === 0) {
                        setDatesList(() => {
                            return data.dates
                        })
                    }
                }
                // mergedData = mergedData
                let tempData = mergedData["Keyword"].filter(
                    (row) => row.name !== "Chars"
                )
                mergedData["Keyword"] = tempData
             })
        } else if (wcSource === "Stocktwits") {
            await getGraphData('worldcloud').then((worldcloudItems) => {
                const data = worldcloudItems[ticker]

                mergedData = filterSourceWCData(
                    data.data,
                    WCViews,
                    wcSource
                )
                try {
                    if (datesList.length === 0) {
                        setDatesList(() => {
                            return data.dates
                        })
                    }
                } catch (r) {
                    if (datesList.length === 0) {
                        setDatesList(() => {
                            return data.dates
                        })
                    }
                }
             })
        } else if (wcSource === "News") {
            await getGraphData('worldcloud').then((worldcloudItems) => { 
                const data = worldcloudItems[ticker]

                mergedData = filterSourceWCData(
                    data.data,
                    WCViews,
                    wcSource
                )
                try {
                    if (datesList.length === 0) {
                        setDatesList(() => {
                            return data.dates
                        })
                    }
                } catch (r) {
                    if (datesList.length === 0) {
                        setDatesList(() => {
                            return data.dates
                        })
                    }
                }
                let tempData = mergedData["Keyword"].filter(
                    (row) => row.name !== "Chars"
                )
                mergedData["Keyword"] = tempData
            })
        }
        // console.log(mergedData, seriesNames, wordcloudLabels)
        let finalSeries = useMemo(() => {
            let series = []
            if (wcSource === "All") {
                for (let key of seriesNames) {
                    let counts = mergedData[key].reduce((prev, curr) => {
                        let count = prev.get(curr.name) || 0
                        prev.set(
                            curr.name,
                            parseInt(curr.weight) + count,
                            curr.color
                        )
                        return prev
                    }, new Map())

                    // then, map your counts object back to an array
                    let reducedObjArr = [...counts].map(
                        ([name, weight, color]) => {
                            return { name, weight, color }
                        }
                    )
                    finalSeries.push({
                        // data: mergedData[key] .filter(i => i.name && i.weight).filter((v,i,a)=>a.findIndex(v2=>['name'].every(k=>v2[k].toLowerCase() ===v[k].toLowerCase()))===i),
                        data: reducedObjArr,
                        name: wordcloudLabels[key],
                        type: "wordcloud",
                        showInLegend: true,
                        color: colorDict[key],
                        // data: wordcloudSeries.filter(i => i.name && i.weight),
                        maxFontSize: 24,
                        spiral: "rectangular",
                        minFontSize: 8,
                        xAxis: seriesNames.indexOf(key),
                        // name:"Occurences",
                        colorByPoint: false,
                        shadow: true,
                        style: {
                            // symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                            width: 20,
                            height: 20
                        }
                    })
                }
                return finalSeries
            } else {
                for (let key of seriesNames) {
                    series.push({
                        data: mergedData[key].filter((i) => i.name && i.weight),
                        name: wordcloudLabels[key],
                        type: "wordcloud",
                        showInLegend: true,
                        maxFontSize: 18,
                        spiral: "rectangular",
                        minFontSize: 8,
                        xAxis: seriesNames.indexOf(key),
                        shadow: true,
                        style: {
                            width: 16,
                            height: 16
                        }
                    })
                }
                return series
            }
        }, [mergedData, seriesNames, wordcloudLabels])
        setWordcloudSeries(() => {
            return finalSeries
        })
    }

    // const wordcloudSeriesRef = useMemo(() => wordcloudSeries, [wordcloudSeries])

    // useEffect(() => {
    //   // console.log(wordcloudSeries, "finalSeries")
    //   wordcloudSeriesRef.current = wordcloudSeries
    // }, [wordcloudSeries, ticker_options])

    useEffect(() => {
        setViews("one_day")
    }, [datesList, props])

    useEffect(() => {
        if (ticker) {
            setData()
        }
    }, [ticker, WCViews, wcSource, props])

    // useEffect(() => {
    //     console.log(wordcloudSeries, "WCS")
    // }, [wordcloudSeries])

    return (
        <div className='mt-3'>
            <FullScreen handle={screen} onChange={setData}>
                <Card
                    style={
                        screen.active
                            ? {
                                  borderRadius: 20,
                                  background:
                                      "linear-gradient(269deg, #D8EBFA 0%, #EBF5FD 100%)",
                                  height: "100%",
                                  overflowY: "scroll"
                              }
                            : {
                                  borderRadius: 20,
                                  background:
                                      "linear-gradient(269deg, #D8EBFA 0%, #EBF5FD 100%)"
                              }
                    }>
                    <CardHeader
                        title={
                            <div className='container-fluid'>
                                <div className='row mb-2'>
                                    <Typography
                                        className='col-md-10 mb-3'
                                        align='left'
                                        variant='h2'
                                        style={{
                                            fontSize: 32,
                                            fontWeight: 600,
                                            fontFamily: "Poppins",
                                            color: "#1F73C1"
                                        }}>
                                        {"Wordcloud"}
                                    </Typography>
                                    <IconButton
                                        onClick={
                                            screen.active
                                                ? screen.exit
                                                : screen.enter
                                        }
                                        className='col-md-auto'
                                        aria-controls='simple-menu'
                                        aria-haspopup='true'
                                        aria-label='Enlarge'
                                        style={{
                                            padding: 0,
                                            marginLeft: "auto",
                                            marginRight: 15
                                        }}>
                                        <FullscreenOutlinedIcon fontSize='large'></FullscreenOutlinedIcon>
                                    </IconButton>
                                </div>
                                <div className='row'>
                                    <Typography
                                        className='col-md-auto mt-2'
                                        align='left'
                                        variant='h4'
                                        style={{
                                            fontSize: 24,
                                            fontWeight: 600,
                                            fontFamily: "Poppins",
                                            color: "#7E7E7E"
                                        }}>
                                        {"Trending Words for "}
                                    </Typography>
                                    {/* + ((ticker === "FB") ? "FB/META" : ticker) : "" */}
                                    <div
                                        className='col-md-2'
                                        style={{ textAlign: "left" }}>
                                        <FormControl
                                            variant='standard'
                                            sx={{ minWidth: 50 }}
                                            style={{
                                                maxWidth: "fit-content",
                                                backgroundColor: "inherit",
                                                color: "black",
                                                textAlign: "left",
                                                height: "50%"
                                            }}
                                            size='small'>
                                            <Select
                                                className='basic-single'
                                                classNamePrefix='select'
                                                variant='standard'
                                                defaultValue={ticker_options[2]}
                                                // placeholder="Source"
                                                isSearchable={true}
                                                name='color'
                                                autoFocus
                                                onChange={(event) => {
                                                    setTicker(() => {
                                                        return event.value
                                                    })
                                                }}
                                                options={ticker_options}
                                                styles={customStyles}
                                            />
                                        </FormControl>
                                    </div>

                                    <div
                                        className='col-md-6 mb-2'
                                        style={{
                                            textAlign: "right",
                                            border: "solid 0px blue"
                                        }}>
                                        <ButtonGroup
                                            className='col-md-auto'
                                            style={{
                                                textAlign: "center",
                                                paddingLeft: "auto"
                                            }}
                                            size='small'
                                            aria-label='text button group'>
                                            <Button
                                                style={{
                                                    backgroundColor:
                                                        selectedDate === 1
                                                            ? "#4b8fcd"
                                                            : "white",
                                                    color:
                                                        selectedDate === 1
                                                            ? "white"
                                                            : "black"
                                                }}
                                                size='small'
                                                variant='contained'
                                                onClick={() => {
                                                    setViews("one_day")
                                                    setSelectedDate(
                                                        (prevState) => {
                                                            return 1
                                                        }
                                                    )
                                                }}>
                                                1 day
                                            </Button>
                                            <Button
                                                style={{
                                                    backgroundColor:
                                                        selectedDate === 2
                                                            ? "#4b8fcd"
                                                            : "white",
                                                    color:
                                                        selectedDate === 2
                                                            ? "white"
                                                            : "black"
                                                }}
                                                size='small'
                                                variant='contained'
                                                onClick={() => {
                                                    setViews("one_week")
                                                    setSelectedDate(
                                                        (prevState) => {
                                                            return 2
                                                        }
                                                    )
                                                }}>
                                                7 days
                                            </Button>
                                            <Button
                                                style={{
                                                    backgroundColor:
                                                        selectedDate === 3
                                                            ? "#4b8fcd"
                                                            : "white",
                                                    color:
                                                        selectedDate === 3
                                                            ? "white"
                                                            : "black"
                                                }}
                                                size='small'
                                                variant='contained'
                                                onClick={() => {
                                                    setViews("two_week")
                                                    setSelectedDate(
                                                        (prevState) => {
                                                            return 3
                                                        }
                                                    )
                                                }}>
                                                14 days
                                            </Button>
                                            <Button
                                                style={{
                                                    backgroundColor:
                                                        selectedDate === 4
                                                            ? "#4b8fcd"
                                                            : "white",
                                                    color:
                                                        selectedDate === 4
                                                            ? "white"
                                                            : "black"
                                                }}
                                                size='small'
                                                variant='contained'
                                                onClick={() => {
                                                    setViews("one_month")
                                                    setSelectedDate(
                                                        (prevState) => {
                                                            return 4
                                                        }
                                                    )
                                                }}>
                                                30 days
                                            </Button>
                                            {/* <Button style={{ backgroundColor: (selectedDate === 5 ? "#4b8fcd" : "lightGrey"), color: (selectedDate === 5 ? "white" : "black") }} size="small" variant="contained" onClick={() => { setViews("two_month"); setSelectedDate((prevState) => { return 5 }) }}>60 days</Button> */}
                                        </ButtonGroup>
                                    </div>
                                    <div
                                        className='col-md-1'
                                        style={{
                                            marginLeft: "auto",
                                            marginRight: "auto"
                                        }}>
                                        <FormControl
                                            sx={{ minWidth: 60 }}
                                            style={{
                                                maxWidth: "fit-content",
                                                backgroundColor: "white",
                                                color: "black",
                                                textAlign: "left"
                                            }}
                                            size='small'>
                                            <Select
                                                className='basic-single'
                                                classNamePrefix='select'
                                                defaultValue={Sources[0]}
                                                // placeholder="Source"
                                                isSearchable={true}
                                                name='color'
                                                autoFocus
                                                onChange={(event) => {
                                                    setSource(() => {
                                                        return event.value
                                                    })
                                                }}
                                                options={Sources}
                                                styles={customStyles}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        }
                    />
                    <CardContent>
                        {/* <Typography variant="h5" className='col-md-3' style={{ textAlign: 'right' }}> Trending Words for {ticker} </Typography> */}
                        <div
                            style={{
                                width: "100%",
                                float: "left",
                                paddingBottom: 15
                            }}
                            // className="step-seven"
                            id='highcharts-container'>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={{
                                    series: wordcloudSeries,
                                    xAxis: [
                                        {
                                            width: "25%"
                                        },
                                        {
                                            left: "25%",
                                            width: "25%"
                                        },
                                        {
                                            left: "50%",
                                            width: "25%"
                                        },
                                        {
                                            left: "75%",
                                            width: "25%"
                                        }
                                    ],

                                    exporting: {
                                        enabled: false
                                    },
                                    title: {
                                        // text: ticker ? "Trending Words by " + ticker : "",
                                        text: "",
                                        style: {
                                            fontSize: 22
                                        }
                                    },
                                    stockTools: {
                                        gui: {
                                            enabled: false
                                        }
                                    },
                                    plotOptions: {
                                        wordcloud: {
                                            colors: [
                                                "#264653",
                                                "#2A9D8F",
                                                "#8E5572",
                                                "#F4A261",
                                                "#B7B5E4"
                                            ]
                                        }
                                    },

                                    credits: {
                                        text: "finsoftai.com",
                                        href: "http://www.finsoftai.com",
                                        style: {
                                            fontSize: 12
                                        },
                                        position: {
                                            align: "right",
                                            x: -10
                                        }
                                    },
                                    legend: {
                                        enabled: true,
                                        // padding:20,
                                        // itemWidth:245,
                                        labelFormatter: function() {
                                            // Customize the legend label here
                                            return '<span class="step-seven">' + this.name + '</span>';
                                          },
                                        align: "center",
                                        layout: "horizontal",
                                        verticalAlign: "top",
                                        itemStyle: {
                                            marginBottom: 30,
                                            fontSize: "16px"
                                            // marginLeft:30
                                            // paddingLeft:20
                                        }
                                        // x: 42,
                                    },
                                    chart: {
                                        height: screen.active ? 700 : 400,
                                        // width:"100%",
                                        spacing: [1, 1, 1, 1],
                                        margin: 1,
                                        renderTo: "highcharts-container",
                                        marginBottom: 20
                                        // marginTop:20
                                    }
                                }}
                            />
                        </div>
                    </CardContent>
                </Card>
            </FullScreen>
        </div>
    )
}

export default Wordcloud


// check for animations
// highlight entities in WC
// test on mobile