import {
    Button,
    Modal,
    Backdrop,
    TextField,
    Grid,
    Typography,
    Card,
    CardContent
} from "@material-ui/core"
import { CardHeader } from "@mui/material"
import IRLogo from "./static/IR_product_sheet_image.png"
import TradingLogo from "./static/Trading_product_sheet_image.png"
import { makeStyles } from "@material-ui/core/styles"
import { getCurrentUser } from "./firebase"
import axios from "axios"
import { useState } from "react"

const useStyles = makeStyles((theme) => ({
    modal: {
        margin: "auto",
        marginTop: "5%",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 3, 2),
        paddingRight: 0
    },
    fullscreenBackground: {
        border: "solid 2px red !important",
        height: "100% !important"
    },
    modal: {
        display: "flex",
        width: "100% !important",
        alignItems: "center",
        justifyContent: "center"
    },
    modalTwo: {
        margin: "auto",
        marginTop: "5%",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 2)
        // maxWidth: "80% !important"
    }
}))

const styles = {
    card: {
        borderRadius: 8,
        boxShadow: "none",
        height: "100%"
    },
    cardHeaderText: {
        color: "#858585",
        fontSize: 24,
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 500
    },
    modalcardHeaderText: {
        color: "#858585",
        fontSize: 24,
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 500,
        textAlign: "center"
    },
    cardText: {
        color: " #858585",
        textAlign: "center",
        fontSize: 18,
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal"
    },
    downloadButton: {
        borderRadius: 25,
        background: "linear-gradient(37deg, #5AB3F6 0%, #027FDC 100%)",
        color: "white",
        fontSize: 14,
        maxWidth: "80%"
    },
    modal: {
        margin: "auto",
        marginTop: "5%",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center"
    }
}
const ProductSheets = (props) => {
    const classes = useStyles()
    const [modalOpen, setModalOpen] = useState(false)
    const [activeModal, setActiveModal] = useState(null)
    const [dataToSend, setData] = useState({
        name: "",
        mobile: "",
        email: ""
    })
    const [disableButton, toggleDisableButton] = useState(false)

    const handleModalOpen = (source) => {
        setModalOpen(true)
        setActiveModal(source)
    }
    const handleModalClose = () => {
        setModalOpen(false)
        setActiveModal(null)
    }

    const sendMails = async (source) => {
        const user = await getCurrentUser()
        console.log(user)
        const data = {
            username: user.displayName + "test",
            number: null,
            email: user.email,
            source: source
        }
        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json"
        }
        console.log(data)
        axios
            .post("https://api.finsoftai.com/send-faang-sheet", data, {
                headers: headers
            })
            .then((resp) => {
                console.log(resp)
                handleModalClose()
                alert(
                    "Mail has been sent to " +
                        user.email +
                        " . Kindly check your spam folder"
                )
                toggleDisableButton(false)
            })
    }

    return (
        <div
            className='mt-3 p-4'
            style={{
                background:
                    "linear-gradient(268deg, rgba(11, 132, 222, 0.13) 0%, rgba(11, 132, 222, 0.16) 0.01%, rgba(87, 178, 245, 0.04) 100%)",
                borderRadius: 15,
                boxShadow:
                    "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
            }}>
            <Typography
                align='left'
                variant='h2'
                style={{
                    color: "#1F73C1",
                    fontSize: 32,
                    fontFamily: "Poppins",
                    fontWeight: 500
                }}>
                {" "}
                Use Cases
            </Typography>
            <Grid
                container
                spacing={4}
                className='mt-2'
                direction='row'
                justifyContent='center'
                alignItems='stretch'>
                <Grid item md={4} xs={6}>
                    <Card sx={{ minWidth: "100%" }} style={styles.card}>
                        <CardHeader
                            style={styles.cardHeaderText}
                            title={"Investment Research"}></CardHeader>
                        <CardContent>
                            <div className='container-fluid'>
                                <div className='row justify-content-center'>
                                    <img
                                        src={IRLogo}
                                        alt='investment research'
                                        style={{
                                            height: "auto",
                                            width: "auto"
                                        }}></img>
                                    <Typography
                                        variant='p'
                                        style={styles.cardText}>
                                        Example Use Case that demonstrates how
                                        the FAANG sentiment dashboard can be
                                        used to enhance investing strategies.
                                    </Typography>

                                    <Button
                                        className='mt-2'
                                        variant='contained'
                                        disabled={disableButton}
                                        onClick={() => {
                                            toggleDisableButton(true)
                                            sendMails("investment")
                                        }}
                                        style={styles.downloadButton}
                                        disableElevation
                                        size='small'>
                                        Download Product Sheet
                                    </Button>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={4} xs={6}>
                    <Card sx={{ minWidth: "100%" }} style={styles.card}>
                        <CardHeader
                            style={styles.cardHeaderText}
                            title={"Trading"}></CardHeader>
                        <CardContent>
                            <div className='container-fluid'>
                                <div className='row justify-content-center'>
                                    <img
                                        src={TradingLogo}
                                        alt='Trading'
                                        style={{
                                            height: "auto",
                                            width: "auto"
                                        }}></img>
                                    <Typography
                                        variant='p'
                                        style={styles.cardText}>
                                        Use Cases that demonstrate the use and
                                        efficacy of the FAANG sentiment
                                        dashboard, that provides alternate data
                                        to maximize trading gains.
                                    </Typography>
                                    <Button
                                        className='mt-2'
                                        variant='contained'
                                        disabled={disableButton}
                                        onClick={() => {
                                            toggleDisableButton(true)
                                            sendMails("trading")
                                        }}
                                        style={styles.downloadButton}
                                        disableElevation
                                        size='small'>
                                        Download Product Sheet
                                    </Button>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Modal
                aria-labelledby='transition-modal-title'
                aria-describedby='transition-modal-description'
                className={classes.modal}
                open={modalOpen}
                //  keepMounted
                onClose={() => {
                    handleModalClose()
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}>
                <Card sx={{ minWidth: "100%" }} style={styles.card}>
                    <CardHeader
                        style={styles.modalcardHeaderText}
                        title={"Enter Details"}></CardHeader>
                    <CardContent>
                        <div
                            classes='container-fluid '
                            className={classes.paper}
                            style={{ maxWidth: "100%" }}>
                            <div className='row'>
                                <div
                                    className='col-md-5'
                                    style={{ marginTop: "auto" }}>
                                    <span>Enter Name</span>
                                </div>
                                <div className='col-md-7'>
                                    <TextField
                                        label='Name'
                                        variant='standard'
                                        value={dataToSend["name"]}
                                        onChange={(event) => {
                                            setData((prevState) => {
                                                return {
                                                    ...prevState,
                                                    name: event.target.value
                                                }
                                            })
                                        }}></TextField>
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div
                                    className='col-md-5'
                                    style={{ marginTop: "auto" }}>
                                    <span>Enter Contact Number</span>
                                </div>
                                <div className='col-md-7'>
                                    <TextField
                                        label='Contact'
                                        variant='standard'
                                        value={dataToSend["mobile"]}
                                        onChange={(event) => {
                                            setData((prevState) => {
                                                return {
                                                    ...prevState,
                                                    mobile: event.target.value
                                                }
                                            })
                                        }}></TextField>
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div
                                    className='col-md-5'
                                    style={{ marginTop: "auto" }}>
                                    <span>Enter Email</span>
                                </div>
                                <div className='col-md-7'>
                                    <TextField
                                        label='Email'
                                        variant='standard'
                                        value={dataToSend["email"]}
                                        onChange={(event) => {
                                            setData((prevState) => {
                                                return {
                                                    ...prevState,
                                                    email: event.target.value
                                                }
                                            })
                                        }}></TextField>
                                </div>
                            </div>
                            <div className='row mt-2 justify-content-center'>
                                <Button
                                    className='mt-2'
                                    variant='contained'
                                    style={styles.downloadButton}
                                    disableElevation
                                    size='small'
                                    onClick={() => {
                                        sendMails(
                                            dataToSend["name"],
                                            dataToSend["mobile"],
                                            dataToSend["email"],
                                            activeModal
                                        )
                                    }}>
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </Modal>
        </div>
    )
}

export default ProductSheets
