import moment from 'moment-timezone'
import market_holidays from '../components/static/data/market_holidays.json'
import api from "../components/api_ui"
import socialBuzzJSON from '../components/static/data/social_buzz.json'
import sentimentJSON from '../components/static/data/sentiment.json'
import socialBuzzChangeJSON from '../components/static/data/social_buzz_change.json'
import sentimentChangeJSON from '../components/static/data/sentiment_change.json'
import worldCloudJSON from '../components/static/data/wordcloud.json'
import onMarketStatusSocialBuzzJSON from '../components/static/data/on_market_status_social_buzz.json'
import onMarketStatusSentimentJSON from '../components/static/data/on_market_status_sentiment.json'
import onMarketStatusSocialBuzzChangeJSON from '../components/static/data/on_market_status_social_buzz_change.json'
import onMarketStatusSentimentChangeJSON from '../components/static/data/on_market_status_sentiment_change.json'
import offMarketStatusSocialBuzzJSON from '../components/static/data/off_market_status_social_buzz.json'
import offMarketStatusSentimentJSON from '../components/static/data/off_market_status_sentiment.json'
import offMarketStatusSocialBuzzChangeJSON from '../components/static/data/off_market_status_social_buzz_change.json'
import offMarketStatusSentimentChangeJSON from '../components/static/data/off_market_status_sentiment_change.json'

const holidayData = market_holidays.marketHolidays[0].list

// Function to check if a date is a holiday
function isHoliday(date) {
    return holidayData.find((holiday) =>
        date.isSame(holiday.date, 'day') && holiday.status === 'CLOSED'
    );
}

// Function to check if a date is a weekend (Saturday or Sunday)
function isWeekend(date) {
    // Check if the given date is a Saturday (6) or Sunday (0).
    return date.day() === 0 || date.day() === 6 || (date.day() === 1 && date.hours() < 9);
}

// Function to check if today is a half-day closure
function checkIfHalfDayClosure(todayET) {
    // Iterate through the holidayData to find a matching holiday
    for (const holiday of holidayData) {
        // Convert the holiday date to the ET timezone
        const holidayDateET = moment(holiday.date).tz('America/New_York').clone();
        
        // Check if the holiday falls on today's date
        if (todayET.isSame(holidayDateET, 'day')) {
            // Check if it's a half-day closure (1 PM closing time)
            if (holiday.status === 'HALF_DAY') {
                return true;
            }
        }
    }
    
    // If no matching half-day closure is found, return false
    return false;
}

const getPrevTradingDay = (todayET) => {
    const prevTradingDay = todayET.subtract(1, 'day').clone()

    if (isHoliday(prevTradingDay) || isWeekend(prevTradingDay)) {
        getPrevTradingDay(prevTradingDay.subtract(1, 'day'))
    }

    return prevTradingDay.clone()
}

// Function to calculate market time based on today's date in ET timezone
function calculateMarketTime(todayET) {
    const prevTradingDay = getPrevTradingDay(todayET.clone());

    const marketStartTime = prevTradingDay.set({ hour: 9, minute: 30 })
    let marketEndTime = prevTradingDay.clone().set({ hour: 16, minute: 0 })

    // Check if Half Day
    const isHalfDayClosure = checkIfHalfDayClosure(todayET)

    if (isHalfDayClosure) {
        marketEndTime = prevTradingDay.set({ hour: 13, minute: 0 })
    }

    return {
        start: marketStartTime.format(),
        end: marketEndTime.format(),
    }
}

// Function to calculate off-market time based on today's date in ET timezone
function calculateOffMarketTime(todayET) {
    const prevTradingDay = getPrevTradingDay(todayET.clone());

    let offMarketStartTime = prevTradingDay.set({ hour: 16, minute: 0 })

    // Check if Half Day
    const isHalfDayClosure = checkIfHalfDayClosure(todayET)

    if (isHalfDayClosure) {
        offMarketStartTime = prevTradingDay.set({ hour: 13, minute: 0 })
    }

    const offMarketEndTime = todayET.set({ hour: 6, minute: 0 })

    return {
        start: offMarketStartTime.format(),
        end: offMarketEndTime.format(),
    }
}

// Function to generate time queries for different timing modes
function generateTimeQueries(providedDate) {
    // const customDate = '2023-10-08'; // Replace with your desired date
    // const todayET = moment(customDate, 'YYYY-MM-DD').tz('America/New_York');
    let date = moment().tz('America/New_York');

    if (providedDate) {
        date = moment(providedDate, 'YYYY-MM-DD').tz('America/New_York');
    }

    const cutoffTime = date.set({ hour: 8, minute: 30, second: 0, millisecond: 0 });

    let assignedDate;
    
    if (date.isBefore(cutoffTime)) {
        // If the current time is before 8:30 AM ET, use the previous day
        assignedDate = date.clone().subtract(1, 'day');
    } else {
        // Otherwise, use the current day
        assignedDate = date;
    }

    // console.log("HERE", assignedDate.format())
    
    const marketTimeQuery = calculateMarketTime(assignedDate.clone());
    const offMarketTimeQuery = calculateOffMarketTime(assignedDate.clone());

    const queries = {
        'on': marketTimeQuery,
        'off': offMarketTimeQuery,
    };

    return queries;
}

const getGraphData = async (graph_type, date, marketTiming, marketTimingkey) => {
    let response

    // If Market Status with Date
    if (date && marketTiming) {
        response = await api.get_market_status_graphs({graph_type, date, marketTiming})

        try {
            response = JSON.parse(response).data
        } catch (e) {
            response = response.data
        }

        return response
    }

    // If Date exists
    else if (date) {
        response = await api.get_graphs(graph_type, date)

        try {
            response = JSON.parse(response).data
        } catch (e) {
            response = response.data
        }

        return response
    }

    // If Market Status exist
    else if (marketTiming && marketTimingkey) {
        if (marketTimingkey === 'on') {
            if (graph_type === "social_buzz")  return onMarketStatusSocialBuzzJSON
            else if (graph_type === "sentiment")  return onMarketStatusSentimentJSON
            else if (graph_type === "social_buzz_change")  return onMarketStatusSocialBuzzChangeJSON
            else if (graph_type === "sentiment_change")  return onMarketStatusSentimentChangeJSON
        } else if (marketTimingkey === 'off') {
            if (graph_type === "social_buzz")  return offMarketStatusSocialBuzzJSON
            else if (graph_type === "sentiment")  return offMarketStatusSentimentJSON
            else if (graph_type === "social_buzz_change")  return offMarketStatusSocialBuzzChangeJSON
            else if (graph_type === "sentiment_change")  return offMarketStatusSentimentChangeJSON
        }
    }
    
    if (graph_type === "social_buzz")  return socialBuzzJSON
    else if (graph_type === "sentiment")  return sentimentJSON
    else if (graph_type === "social_buzz_change")  return socialBuzzChangeJSON
    else if (graph_type === "sentiment_change")  return sentimentChangeJSON
    else if (graph_type === "worldcloud")  return worldCloudJSON
}

export { generateTimeQueries, getGraphData }
