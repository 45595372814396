import { Grid, Typography } from "@material-ui/core"
import mainVideo from "./static/Faang video.mp4"
import thumbnail from "./static/video_thumbnail.jpg"

const VideoSection = (props) => {
    return (
        <>
            <Grid
                container
                justifyContent='space-around'
                spacing={2}
                style={{ paddingBottom: 8, textAlign: "left" }}>
                {" "}
                <Grid item xs={12} md={5}>
                    <video
                        controls
                        style={{
                            border: "solid 0px black",
                            maxWidth: "100%",
                            height: "auto"
                        }}
                        poster={thumbnail}>
                        <source src={mainVideo} type='video/mp4' />
                    </video>
                </Grid>
                <Grid item xs={12} md={6} style={{ minHeight: "40vh" }}>
                    <br></br>
                    <Typography
                        className='mt-5'
                        align='left'
                        variant='h2'
                        style={{
                            color: "#1F73C1",
                            fontSize: 32,
                            fontFamily: "Poppins",
                            fontWeight: 500
                        }}>
                        {"Product Overview"}
                    </Typography>
                    <br></br>
                    <Typography
                        className='mt-5'
                        align='left'
                        variant='span'
                        style={{
                            color: "#858585",
                            fontSize: 20,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            textAlign: "left"
                        }}>
                        The FAANG companies combined account for approx. 15% of
                        the S&P 500 and 30% of Nasdaq 100. <br />
                        At FinSoftAi, we use Machine Learning to understand
                        feeds from social media & news, to provide unique and
                        alternative insights through our FAANG sentiment
                        dashboards. <br />
                        This dashboard serves Financial Media, Investment
                        Managers, Wealth Managers, Traders and Risk Managers.
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
}

export default VideoSection
