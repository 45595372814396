import "./App.css"
import { useState } from "react"
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom"
import Login from "./components/Login"
import { history } from "./components/history"
import Graphs from "./components/Graphs"
import Register from "./components/Register"
import Reset from "./components/Reset"

function App() {
    const [modalOpen, setModalOpen] = useState(false)

    return (
        <div className='App'>
            <BrowserRouter history={history}>
                <Switch>
                    <Route exact path='/login' component={Login} />
                    <Route
                        path='/board'
                        component={() => (
                            <Graphs
                                modalToggle={modalOpen}
                                setModalToggle={(data) => {
                                    setModalOpen(data)
                                }}
                            />
                        )}
                    />
                    <Route exact path='/register' component={Register} />
                    <Route exact path='/reset' component={Reset} />

                    <Route exact path='/'>
                        <Redirect to='/login' />
                    </Route>
                </Switch>
            </BrowserRouter>
        </div>
    )
}

export default App
