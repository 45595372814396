import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { useState } from 'react'

const Switcher = ({ items, onSelect, selected }) => {
  const [selectedItem, setSelectedItem] = useState(selected.value)

  const handleChange = (event, value) => {
    setSelectedItem(value)

    onSelect(value)
  }

  return (
    <ToggleButtonGroup
      color="primary"
      value={selectedItem}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >
      {
        items.map((item, key) => {
          const { label, value } = item
          return (
            <ToggleButton value={value} style={{ padding: '0.5rem', borderRadius: '5px' }}>{label}</ToggleButton>
          )
        })
      }
    </ToggleButtonGroup>
  )
}

export default Switcher