import { Grid, Typography } from "@material-ui/core"
import FSAILogo from "./static/fsai_logo.png"

const Footer = () => {
    return (
        <div>
            <Grid container spacing={4} direction="row" justifyContent="space-between" alignItems="end" className='mt-1 mb-1'>
                <Grid item md={5} xs={12} style = {{textAlign:"left", marginTop:5,color:" #616161",fontSize: 14,fontFamily: "Arial",
                fontStyle: "normal",fontWeight: 400, marginLeft:"5%"}}>
                    <Typography variant="p">
                    FinSoftAI Solutions<br/><a href="tel:+91 99754 95426">+91 99754 95426</a><br/><a href="emailto:contactus@finsoftai.com">contactus@finsoftai.com</a><br/><a href="https://faang.finsoftai.com">https://faang.finsoftai.com</a>
                    </Typography>
                </Grid>
                <Grid
                    item
                    md={5}
                    xs={12}
                    style={{
                        textAlign: "right",
                        marginTop: "auto",
                        marginRight: "5%"
                    }}>
                    <img
                        src={FSAILogo}
                        alt='LOGO'
                        width={132}
                        height={45}
                        style={{ marginBottom: 10 }}></img>
                    <br></br>
                    <Typography variant='p'>
                        <a
                            href={process.env.REACT_APP_APP_URL}
                            target='_blank'
                            style={{
                                color: " #616161",
                                fontSize: 14,
                                fontFamily: "Arial",
                                fontStyle: "normal",
                                fontWeight: 400
                            }}
                            rel='noreferrer'>
                            © 2023 FinSoftAi - Social Sentiment Insights{" "}
                        </a>
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default Footer
